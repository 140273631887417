<template>
  <div class="hello">
    <h1 style="text-align: center">{{ msg }}</h1>
    <ol>
      <li>
        <h3 style="font-weight: bold; color: red">
          锚定赚钱效应，情绪，筹码价位就行了，而不是股价，特别是赚钱效应，当没有跌停的时候就是情绪好转的时候，这个时候可以尝试了,情绪都是有周期的,
          <span style="color: rgb(81, 0, 255)"
            >我经常说赚钱都是市场给的，至于市场怎么给呢？那就是赚钱效应，</span
          >至于筹码，那就是这个股票不能有太多的套牢盘，有套牢盘的话谁去给他们解套呢？要思考这个问题
        </h3>
      </li>
      <li style="font-weight: bold; color: red">
        92科比说的周期理论，主升，高位震荡，主跌，低位试错，当进入主跌行情的时候也就是有七八个股票跌停赚钱效应不好的时候，这个时候就考虑空仓休息了，这个时间只要大环境不是很恶劣一般都要三天差不多，然后等主跌周期结束之后，就可以开始低位试错了
      </li>
      <li style="font-weight: bold; color: red">
        如果要进攻做右侧就要锚定五日线和十日线，破位了就要走就不能看了，如果想要防守做左侧，就要锚定20日线和60日线，如果破了20日线就要往60日线看了，做交易不能只做防守，不做进攻，这也是很重要的一点
      </li>
      <li style="font-weight: bold; color: rgb(81, 0, 255)">
        如果做右侧也就是锚定5日线和10日线的操作，当拉出最后一根大阳线的时候，然后隔天出现阴线的时候，这个时候是千万不能买，在这个位置要在最后一根阳线的基础上观察四到五天，看能不能站稳10日线，如果能站稳不创新低就可以买入了
      </li>
      <li>
        玩A股玩的就是情绪，自己可以好好感受一下是不是，所以行情不好千万不要有赚钱的想法，可以考虑休息一下
      </li>
      <li>
        玩A股就像是打游戏，必须注意细节，一场比赛想要赢，必须猥琐发育，注意各种细节，不然浪一波就要功亏一篑，本来赢的局面变成了输的局面，如果一直连败就要考虑休息一下
      </li>
      <li>玩A股一定要管住手，否则盈利一下就能给你干回去</li>
      <li>
        行情不好的时候，不亏，小亏都是赚，你想一想，如果你没有管住手，当天亏钱了，你第二天大概率还要亏钱，相当于你如果没有管住手你要亏两次钱
      </li>
      <li>
        买股票一定要买向上趋势的，千万不要买向下趋势的，想一下，一只股票想赚钱，必须拉升，拉升他就意味肯定是向上走的，向上都是获利盘，而向下就全是套牢盘，难道你要去解放套牢盘吗，不要想着是洗盘什么的，没必要，性价比不高
      </li>
      <li>
        如果要左侧买入，最好买那种价格高于日内均线的，或者买入在均线附近上下跳动的
      </li>
      <li style="font-weight: bold">
        想想北京炒家，这么大的体量人家都能忍住不动手，所以才走到了今天，管住手这个优点真的要向他学习，再想想你朋友圈的那些人，你真的比他们差吗？不，你是缺少机会，而管住手将会让你的机会更多，赢的几率更大
      </li>
      <li style="font-weight: bold; color: red">
        当你在看这些话的时候是不是又想买卖了，忍住
      </li>
      <li style="font-weight: bold; color: red">
        亏钱宁愿亏在右侧，也不要亏在左侧，在A股玩股票还是要多玩玩右侧交易
      </li>
      <li style="font-weight: bold; color: red">
        记住，一切的你想买卖的冲动都是因为情绪，你要学会控制自己，控制自己的情绪
      </li>
    </ol>
    <div class="img_warpper">
      <img
        v-for="(item, index) in imgList"
        :key="index"
        :src="require(`@imgs/${index + 1}.jpeg`)"
        alt=""
      />
      <!-- <img :src="require('@imgs/2.jpeg')" alt=""> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      imgList: new Array(8).fill(1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  padding: 0;
  list-style: none;
}
li {
  margin: 0 10px;
  padding: 10px 0;
}
a {
  color: #42b983;
}
.img_warpper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}
img {
  width: 300px;
  height: 100%;
  margin-bottom: 60px;
}
</style>
